export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Aset Bersertifikat',
        to: '/asetwilayah',
        icon: 'cil-folder-open',
      },    
      {
        _name: 'CSidebarNavItem',
        name: 'Aset Belum Bersertifikat',
        to: '/asetunregis',
        icon: 'cil-folder-open',
      },       
      {
        _name: 'CSidebarNavItem',
        name: 'Verifikasi Data',
        to: '/verifikasi',
        icon: 'cil-folder-open',
      }, 
      {
        _name: 'CSidebarNavItem',
        name: 'Verifikasi Lapangan',
        to: '/verlap',
        icon: 'cil-folder-open',
      }, 
      {
        _name: 'CSidebarNavItem',
        name: 'Validasi Akhir',
        to: '/validasi',
        icon: 'cil-folder-open',
      }, 
      {
        _name: 'CSidebarNavDropdown',
        name: 'Laporan',
        route: '/laporan',
        icon: 'cil-folder-open',
        items: [
          {
            name: 'Laporan per Dinas',
            to: '/laporandinas'
          },
          {
            name: 'Laporan per Wilayah',
            to: '/laporanwilayah'
          },
          {
            name: 'Laporan per Hak',
            to: '/laporanhak'
          },
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        name: 'Setting',
        _children: ['Setting']
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Log  Aktivitas',
      //   to: '/aktivitas',
      //   icon: 'cil-folder-open',
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Profil Saya',
        to: '/profilsaya',
        icon: 'cil-folder-open'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-account-logout'
      }
    ]
  }
]