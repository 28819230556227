<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
          <CIcon name="cil-bell"/> <sup v-if="notiflengths>0"><strong><CBadge color="danger" class="position-static">{{notiflengths}}</CBadge></strong></sup>
      </CHeaderNavLink>
    </template>
    <CDropdownItem> Notifikasi </CDropdownItem>
    <CDropdownDivider />
    <div v-if="notiflengths>0">
      <CDropdownItem v-for="rs in notifs"> <CIcon name="cil-transfer" /> {{rs.nama_peminjam}} meminjam arsip {{rs.nomor_hak}} - {{tglIndo(rs.tgl_pinjam,'DD/MM/YYYY')}} </CDropdownItem>
    </div>
    <div v-if="notiflengths==0">
      <CDropdownItem> <CIcon name="cil-bell" /> Belum ada notifikasi terkini </CDropdownItem> 
    </div>
  </CDropdown>
</template>

<script>
import axios from 'axios';
let user = JSON.parse(localStorage.getItem("user"));
export default {
  name: "TheHeaderDropdownNotif",
  data() {
    return {
      itemsCount: 42,
      nickname: user.name,
      notifs: [],
      notiflengths : 0,
    };
  },
  mounted(){
    this.loadData();
  },
  methods: {
    tglIndo: function (value, formatString) {

      if (value != undefined)
        return '';

      if (formatString != undefined){
        return moment(value).format(formatString);
      }
      else{
        return moment(value).format('DD/MM/YYYY');  
      }      
    },
    logoutNow: function () {
      localStorage.setItem("user", null);
      localStorage.setItem("jwt", null);
      this.$router.push("/login");
    },
    loadData: function() {
               
        axios
        .get(
          process.env.VUE_APP_BASE_URL+"index.php/notifikasi",false
        )
        .then((response) => {
          this.notifs=response.data;
          this.notiflengths = this.notifs.length;
        });
    }    
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>